// ChatComponent.js

import React, { Component } from 'react';

class ChatComponent extends Component {
  componentDidMount() {
    // Carregue o script Zenvia Chat quando o componente for montado
    const script = document.createElement('script');
    script.src = 'https://static.zenvia.com/embed/js/zenvia-chat.min.js';
    script.async = true;

    script.onload = () => {
      // Inicialize o chat após o script ser carregado
      const chat = new window.ZenviaChat('458c55a67edbbd24fa1aea39f99b90b0').embedded('button').build();
    };

    document.body.appendChild(script);
    // Defina a variável global para indicar que o script já foi carregado
    window.zenviaChatLoaded = true;
  }

  componentWillUnmount() {
    // Limpe o script quando o componente for desmontado
    const script = document.querySelector('script[src="https://static.zenvia.com/embed/js/zenvia-chat.min.js"]');
    if (script) {
      document.body.removeChild(script);
    }
  }

  render() {
    return (
      <div>
        {/* O componente onde você deseja incorporar o chat */}
      </div>
    );
  }
}

export default ChatComponent;
