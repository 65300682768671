import { useContext, useEffect, useState } from "react";
import exportFromJSON from 'export-from-json'

import PageContainer from "../../components/PageContainer";
import { FilterArea, FilterButton, ModalContent, ModalButton, ShowPerPageArea, ExcelButton } from "./styles";
import FilterIcon from '../../assets/icons/filter.png';
import ExcelIcon from '../../assets/icons/excel.png';
import SearchIcon from '../../assets/icons/search.png';
import InputWithIcon from "../../components/InputWithIcon";
import Table from "../../components/Table";
import Pagination from "./Pagination";
import Modal from "../../components/Modal";
import { GlobalContext } from "../../contexts/global";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "../../utils/toast";



export default function Products() {
  const [modalOpened, setModalOpened] = useState(false);
  const [showPerPage, setShowPerPage] = useState(500);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [body, setBody] = useState([]);
  const [database, setDatabase] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  


var wasPressed = false;

  const { filterType, filter } = useParams();

  const { products, setProducts, filteredProducts } = useContext(GlobalContext);

  const title = filterType ? `Produtos - ${filterType}: ${filter}` : 'Produtos Cadastrados'
  const header = ["EAN do Parceiro", "Cod Ref Domazzi", "Descrição Domazzi", "Descrição Parceiro", "Exportador", "Categoria", "Parceiro", "Estoque Disponível", "Preço Compra(R$)","Preço Venda(R$)","Qtd Ultima Saída","Qtd Ultima Compra","Estoque 3 Meses","Estoque 12 Meses","Data Ult. Compra", "Data Ult. Saída","Primeiro Processamento","Ultima Atualização"];

  const filters = [
    // {label: 'Produto', inTable: 'Descrição Domazzi'},
    { label: 'EAN Parceiro', inTable: 'EAN do Parceiro' },
    { label: 'Estoque Disponível', inTable: 'Estoque Disponível' },
    { label: 'Nome Parceiro', inTable: 'Parceiro' },
    { label: 'Categoria', inTable: 'Categoria' },
    // {label: 'Ref.Parceiro', inTable: 'Referência Parceiro'},
    { label: 'Ref.Domazzi', inTable: 'Cod Ref Domazzi' },
    { label: 'Desc.Parceiro', inTable: 'Descrição Domazzi' },
  ];
  const [selectedFilters, setSelectedFilters] = useState([]);
  
  document.addEventListener('keydown', function(event) {
    // Verifique se a tecla pressionada é a tecla F5
    if (event.key === 'F5') {
      toastSuccess("Dados atualizados");

      event.preventDefault();
      toastSuccess("Dados atualizados");


    }
  });
  setTimeout(function() {
    // Fecha o alerta chamando o método "window.close()"
    // Isso funciona apenas no Chrome e em alguns outros navegadores
    window.close();
  }, 3000);
  window.addEventListener('beforeunload', function (event) {
    toastSuccess("Dados atualizados");

    // Aqui você pode exibir uma mensagem personalizada para o usuário
    // Você não pode personalizar a mensagem exibida em todos os navegadores
    navigate('/produtos-cadastrados');  
  });

  window.addEventListener('unload', function (event) {
    toastSuccess("Dados atualizados");

    // Aqui você pode exibir uma mensagem personalizada para o usuário
    navigate('/produtos-cadastrados');  

  });
  function handleSetShowPerPage(e) {
    e.target.value
      ? setShowPerPage(e.target.value)
      : setShowPerPage(10)
  }

  function handleFilter() {
    let newData = [...products];

    selectedFilters.forEach((filter, filterIndex) => {
      if (filter.value) {
        const index = header.findIndex(element => element === filter.inTable);

        if (filterIndex === 0) {
          newData = database.filter(row => {
            return row[index];
          });
        } else {
          newData = newData.filter(row => {
            return row[index];
          });
        }
      }
    })

    setOffset(0);
    setData(newData);
    setModalOpened(false);
  }

  function handleSearch() {
    if (search) {
      let newData = database.filter(row => {
        return row[1].toUpperCase().includes(search.toUpperCase());
      });
      if(newData.length>0){
        setData(newData);

      }else{
        if (search) {
          let newData = database.filter(row => {
            return row[2].toUpperCase().includes(search.toUpperCase());
          });
          if(newData.length>0){
            setData(newData);
    
          }else{
            if (search) {
              let newData = database.filter(row => {
                let retorno = row[7] + '';
                return retorno.toUpperCase().includes(search.toUpperCase());
              });
              if(newData.length>0){
                setData(newData);
        
              }else{
                if (search) {
                  let newData = database.filter(row => {
                    let retorno2 = row[8] + '';

                    return retorno2.includes(search.toUpperCase());
                  });
                  if(newData.length>0){
                    setData(newData);
            
                  }else{
                    if (search) {

                      let newData = database.filter(row => {
                        let retorno3 = row[0] + '';

                        return retorno3.includes(search.toUpperCase());
                      });
                      if(newData.length>0){
                        setData(newData);
                
                      }else{
                        setData(newData);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {

      

   
      setData(database);
    
    }
  }

  function handleExcelExport() {
    const result = data.map((arr) => {
      const obj = {};

      header.forEach((prop, i) => {
        obj[prop] = arr[i];
      });

      return obj;
    });

    exportFromJSON({
      data: result,
      fileName: 'Produtos Domazzi',
      exportType: exportFromJSON.types.xls
    });
  }

  useEffect(() => {
    setBody(() =>
      data.slice(offset, offset + Number(showPerPage))
    )
  }, [offset, showPerPage]);

  useEffect(() => {
    setOffset(0);
  }, [showPerPage]);

  useEffect(() => {
    setBody(() =>
      data.slice(offset, offset + Number(showPerPage))
    )
  }, [data]);

  useEffect(() => {
    filteredProducts.length === 0
      ? setData(() =>
        database.map(product => {
          const row = Object.keys(product).map(key => product[key]);
          return row.toUpperCase();
        })
      )
      : setData(() =>
        filteredProducts.map(product => {
          const row = Object.keys(product).map(key => product[key]);
          return row;
        })
      );

  }, [filteredProducts]);

  useEffect(() => {
    if (filterType) {
      const label = filterType.charAt(0).toUpperCase() + filterType.slice(1);
      setSelectedFilters([
        { label, value: filter, inTable: filterType }
      ]);
    } else {
      setSelectedFilters([]);
    }
  }, [filterType]);

  useEffect(() => {
    if (products.length) {
      setDatabase(() => {
        const data = products.map(product => {
          const row = Object.keys(product).map(key => product[key]);
          return row;
        });
        setData(data);
        return data;
      })
    }
  }, [products]);


  return (
    <PageContainer title={title} >
      <FilterArea>
      <ExcelButton onClick={handleExcelExport}>
            <img src={ExcelIcon} alt="" />
            Exportar Excel
          </ExcelButton>

        <div>
          <label >
            <InputWithIcon
              placeholder="Busque por: Ean parceiro, Descrição Domazzi, Estoque disponível, Cod Referencia"
              right={{ src: SearchIcon, onClick: () => handleSearch() }}
              value={search}
              onEnter={handleSearch}
              setValue={setSearch}
            />
          </label>

        </div>
        
      </FilterArea>
      <Modal opened={modalOpened} setOpened={setModalOpened}>
        <ModalContent>
          <header>
            <h2>Filtrar produtos</h2>
          </header>

          <label>Selecione o filtro:</label>

          <div>
            {filters.map(filter => (
              <label key={filter.label}>
                <input
                  type="checkbox"
                  defaultChecked={selectedFilters.some(element => element.label === filter.label)}
                  value={filter.label}
                  onChange={() => {
                    setSelectedFilters(old => {
                      let newFilters = [...old];
                      const index = newFilters.findIndex(element => element.label === filter.label);
                      index === -1
                        ? newFilters.push({ ...filter, value: '' })
                        : newFilters.splice(index, 1);
                      return newFilters;
                    })
                  }}
                />

                {filter.label}
                {selectedFilters.some(element => element.label === filter.label) &&
                  <input
                    type="text"
                    placeholder="Insira a descrição"
                    defaultValue={selectedFilters.find(element => element.label === filter.label).value}
                    onChange={e => {
                      let newFilters = [...selectedFilters];
                      const index = newFilters.findIndex(element => element.label === filter.label);
                      newFilters[index].value = e.target.value;
                      setSelectedFilters(newFilters);
                    }}
                  />
                }
              </label>
            ))}
          </div>

          <div>
            <ModalButton text="Fechar" color="red" onClick={() => setModalOpened(false)} />
            <ModalButton text="Aplicar Filtro" color="branding" onClick={handleFilter} />
          </div>
        </ModalContent>
      </Modal>
      

      <ShowPerPageArea>
        <label>Mostrar</label>

        <input type="number" value={showPerPage} onChange={handleSetShowPerPage} inputMode="numeric" pattern="\d*" min={0} />
        <label>Produtos por página</label>
        
      </ShowPerPageArea>

      <Table header={header} body={body} />

      <Pagination limit={showPerPage} total={data.length} offset={offset} setOffset={setOffset} showPerPage={showPerPage} />
    </PageContainer>
  )
}